<template>
  <div class="message-list">
    <Header />
    <div v-for="item in list" :key="item.sendBatch" @click="toDetail(item)">
      <Card 
        :name="item.name" 
        :total="item.total" 
        :content="item.smsContent"
      />
    </div>
    <InfiniteLoading 
      :distance="200" 
      spinner="waveDots"
      @infinite="getList" 
    >
      <div slot="no-more" class="infinite-loading-no-more">
        暂无更多
      </div>
    </InfiniteLoading>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Card from './components/card';
import Header from '@/components/header';
import { fetchMessageList } from '@/api/message';
import InfiniteLoading from "vue-infinite-loading";
export default {
  components: {
    Card,
    Header,
    InfiniteLoading,
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      list: [],
      statusMap: {
        1: '业务主任审核',
        2: '区域经理审核',
        3: '项目部审核',
        '-1': '退回',
        0: '审核完成'
      }
    }
  },
  computed: {
    approveStatus() {
      return parseInt(this.$route.query.status) || '';
    },
    createDate2() {
      if (this.approveStatus === 2) {
        const date = new Date();
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return dayjs(lastDay).format("YYYY-MM-DD");
      } else {
        return '';
      }
    },
    createDate1() {
      if (this.approveStatus === 2) {
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        return dayjs(firstDay).format("YYYY-MM-DD");
      } else {
        return '';
      }
    }
  },
  methods: {
    /**
     * 获取短信列表
     */
    async getList($state) {
      const res = await fetchMessageList({
        approveStatus: this.approveStatus,
        createDate1: this.createDate1,
        createDate2: this.createDate2,
        "page.pageSize": this.pageSize,
        "page.pageNo": this.pageNum,
        // page: {
        //   pageNum: this.pageNum,
        //   pageSize: this.pageSize,
        // }
      });
      res.data.forEach(item => item.name = `审核状态：${item.approveStatusName}`);
      this.pageNum++;
      this.list = this.list.concat(res.data);
      if (res.page.hasNextPage) {
        $state.loaded();
      } else {
        $state.loaded();
        $state.complete();
      }
    },
    /**
     * 跳转详情页
     */
    toDetail(item) {
      this.$router.push(`/message/detail?sendBatch=${item.sendBatch}`);
    },
  },
}
</script>

<style lang="scss" scoped>
.message-list {
  min-height: 100vh;
  background-color: #FAFBFC;
}
</style>